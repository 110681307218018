export const castToString = (value: any): string => {
  if (
    value === null ||
    value === undefined ||
    value === false ||
    value === 'false' ||
    value === 'undefined' ||
    value === 'null' ||
    typeof value === 'object'
  ) {
    return '';
  }

  return value.toString();
};

export const castToBoolean = (value: any): boolean => {
  if (
    value === null ||
    value === undefined ||
    value === 'false' ||
    value === 'undefined' ||
    value === 'null' ||
    value === 'no'
  ) {
    return false;
  }

  return !!value;
};

export const fillWithZeros = (number: any) => {
  if (Number(number) < 10) {
    return `0${Number(number)}`;
  }
  return number;
};

const convertFinancialNumberInFloat = (number) => {
  let stringNumber = number.toString();

  const searchRegExp = /\./g;
  stringNumber = stringNumber.replace(/R\$\s*/g, '');
  stringNumber = stringNumber.replace(searchRegExp, '');
  stringNumber = stringNumber.replace(',', '.');
  return Number(stringNumber);
};

export const setNumericValueOrNull = (data) =>
  data === '' || data === null || data === undefined ? null : convertFinancialNumberInFloat(data);

export const mountUrlLooker = (id): string => `https://pravaler.sa.looker.com/embed/dashboards/${id}`;

export const convertObjectToString = (objectItem: unknown): string => {
  try {
    if (typeof objectItem === 'object') {
      return JSON.stringify(objectItem);
    }
    return `${objectItem}`;
  } catch (error) {
    return `${objectItem}`;
  }
};

export const convertStringToObject = <T>(objectItem: string, defaultValue?: T): T => {
  try {
    return JSON.parse(objectItem);
  } catch (error) {
    return defaultValue;
  }
};

export const convertTxtToDownloadFile = (text: string): any => {
  const blobFile = new Blob([text], {
    type: 'text/plain'
  });

  return window.URL.createObjectURL(blobFile);
};

export const setValueOrNull = (data) => (data === '' || data === undefined ? null : data);

export const formatUserName = (fullName: string): { firstLetter: string; formatedName: string } => {
  const slicedName = fullName.split(' ');

  return {
    firstLetter: slicedName[0].charAt(0),
    formatedName: `${slicedName[0]} ${slicedName[slicedName.length - 1]}`
  };
};

export interface IDropDownData {
  label: string;
  value: string | number | boolean;
}

export const convertDataToDropDownData = (dataApi: Array<any>): IDropDownData[] => {
  const convertedList = [];
  if (dataApi) {
    dataApi.forEach((item) => {
      convertedList.push({
        value: item.id,
        label: item?.nome || item?.name
      });
    });
  }
  return convertedList;
};

export type conversionRuleType = {
  value: string;
  label: string;
  active?: string;
  statusColor?: 'inactive' | 'default';
  labelDescription?: string;
  secondLabelDescription?: string;
};

export interface conversionRuleTypeOutput extends IDropDownData {
  active?: string;
  statusColor?: 'inactive' | 'default';
  labelDescription?: string;
  secondLabelDescription?: string;
}

export function convertApiResponseToDropdownFormat<T = any>(
  data: T[],
  rule: conversionRuleType
): conversionRuleTypeOutput[] {
  return data?.map((item) => ({
    value: item?.[rule.value],
    label: item?.[rule.label],
    statusColor: item?.[rule.active] === false ? 'inactive' : 'default',
    labelDescription: rule.labelDescription ? item?.[rule.labelDescription] : '',
    secondLabelDescription: rule.secondLabelDescription ? item?.[rule.secondLabelDescription] : ''
  }));
}

export const calculate = (total: string, value: string) => {
  const castTotal = total.replace(/\./g, '').replace('R$', '').replace(',', '.').trim();
  const castValue = value.replace(/\./g, '').replace('R$', '').replace(',', '.').trim();
  const calculateValue = Number(castTotal) - Number(castValue);
  const resultValue = calculateValue > 0 ? calculateValue : 0;

  return resultValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).replace(/\s/, ' ');
};

export const calculatePercentage = (total: string, value: string) => {
  const castTotal = Number(total.replace(/\./g, '').replace('R$', '').replace(',', '.').trim());
  const castValue = Number(value);
  const calculateValue = (castTotal / 100) * castValue;
  const minusValue = castTotal - calculateValue;
  const resultValue = minusValue > 0 ? minusValue : 0;

  return resultValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).replace(/\s/, ' ');
};

export const normalizeStringAccents = (value?: string): string =>
  value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') || '';

export interface IMaxAndMinMonthy {
  max: number;
  min: number;
}

export const normalizeMaxAndMinMonthy = (dataApi: Array<any>): IMaxAndMinMonthy[] => {
  const list = [];
  if (dataApi) {
    dataApi.forEach((item) => {
      list.push({
        max: item.maximoMensalidades,
        min: item.minimoMensalidades
      });
    });
  }
  return list;
};

export interface IMaxOverdueTuition {
  maxOverdue: number;
}

export const normalizeOverdueTuition = (dataApi: Array<any>): IMaxOverdueTuition[] => {
  const list = [];
  if (dataApi) {
    dataApi.forEach((item) => {
      list.push({
        maxOverdue: item.maximoMensalidadesAtraso
      });
    });
  }
  return list;
};

export function fillCpf(cpf: string) {
  return cpf?.replace(/\D/g, '')?.padStart(11, '0') || '00000000000';
}

export const toCapitalize = (text: string): string => {
  if (!text) {
    return text;
  }

  const firstLetter = text.charAt(0);
  const restLetters = text.slice(1);
  return `${firstLetter.toUpperCase()}${restLetters.toLowerCase() || ''}`;
};

export const removeDuplicateItems = (items: string[]): string[] => {
  const itemsNonDuplicates: string[] = [];

  items.forEach((item) => {
    if (itemsNonDuplicates.includes(item)) {
      return;
    }

    itemsNonDuplicates.push(item);
  });

  return itemsNonDuplicates;
};

const monthNameByNumber1To12 = {
  '01': 'Janeiro',
  '02': 'Fevereiro',
  '03': 'Março',
  '04': 'Abril',
  '05': 'Maio',
  '06': 'Junho',
  '07': 'Julho',
  '08': 'Agosto',
  '09': 'Setembro',
  '10': 'Outubro',
  '11': 'Novembro',
  '12': 'Dezembro'
};

/**
 *
 * @description Returns month name em pt-br from number month
 * @param {String} month example 1, 01, 12, etc
 * @returns {String} month name int pt-br, example Janeiro, Dezembro
 */
export const getMonthNameByNumber1To12 = (month: string | number): string | undefined =>
  monthNameByNumber1To12[String(month).padStart(2, '0')];

export const generateArrayWithSize = (length: number): number[] => Array.from({ length }, (_ignore, index) => index);
