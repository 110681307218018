import monthsOfYearEnum from '@/enum/monthsEnum';

export const SESSION_STUDENTS_FILTER_KEY = 'studentsFilter';
export const COOKIE_MAIN_PORTAL: string = 'portalie';
export const SESSION_PORTAL_PROFILE: string = 'portalie';
export const STORAGE_TOUR: string = 'portalieTour';
export const NOT_EXISTS_VALUE: number = -1;
export const CPF_WITHOUT_MASK_LENGTH = 11;
export const MONTHS_IN_ONE_YEAR: number = 12;
export const MINIMUM_MONTHLY_VALUE_POSSIBLE: number = 150.0;
export const EMAIL: string = 'relacionamento.ies@pravaler.com.br';
export const REFRESH_INTERVAL_SWR_IN_MS: number = 1000;
export const HOTJAR_ID: number = 2421887;
export const GTAG_ID: string = 'GTM-5L26V7X';
export const HOTJAR_SNIPPET_VERSION: number = 6;
export const ONE_SECOND_IN_MS: number = 1000;
export const ONE_MINUTE_IN_SECONDS = 60;
export const THREE_SECONDS_IN_MS: number = 3000;
export const TIME_IN_SECONDS_CLOUDFLARE_TIMEOUT: number = 60;
export const DEGREE_POSTGRADUATE: number = 2;
export const DEGREE_BASIC_EDUCATION: number = 5;
export const EMPTY_STRING = '';
export const TIME_IN_SECONDS_TO_UPDATE_BATCH_APPROVAL: number = 90;
export const TIME_IN_MILLISECONDS_TO_UPDATE_BATCH_APPROVAL: number =
  ONE_SECOND_IN_MS * TIME_IN_SECONDS_TO_UPDATE_BATCH_APPROVAL;
export const LIMIT_REQUEST_BATCH_APPROVAL: number = 20;
export const CLOUD_FLARE_STATUS_CODE_ERROR: number[] = [522, 524, undefined];
export const BASE_DECIMAL: number = 10;
export const SMALL_SCREEN_SIZE: number = 768;

export const TIME_LIMIT_COOKIE = 60 * 60 * 6; // 6 hours

export const monthsByNumber = {
  JAN: 1,
  FEV: 2,
  MAR: 3,
  ABR: 4,
  MAI: 5,
  JUN: 6,
  JUL: 7,
  AGO: 8,
  SET: 9,
  OUT: 10,
  NOV: 11,
  DEZ: 12
};

export const monthsIndex = {
  JAN: 0,
  FEV: 1,
  MAR: 2,
  ABR: 3,
  MAI: 4,
  JUN: 5,
  JUL: 6,
  AGO: 7,
  SET: 8,
  OUT: 9,
  NOV: 10,
  DEZ: 11
};

export const monthsByString = {
  [monthsByNumber.JAN]: 'Janeiro',
  [monthsByNumber.FEV]: 'Fevereiro',
  [monthsByNumber.MAR]: 'Março',
  [monthsByNumber.ABR]: 'Abril',
  [monthsByNumber.MAI]: 'Maio',
  [monthsByNumber.JUN]: 'Junho',
  [monthsByNumber.JUL]: 'Julho',
  [monthsByNumber.AGO]: 'Agosto',
  [monthsByNumber.SET]: 'Setembro',
  [monthsByNumber.OUT]: 'Outubro',
  [monthsByNumber.NOV]: 'Novembro',
  [monthsByNumber.DEZ]: 'Dezembro'
};

export const firstSemesterMonths = [
  monthsOfYearEnum.JAN,
  monthsOfYearEnum.FEV,
  monthsOfYearEnum.MAR,
  monthsOfYearEnum.ABR,
  monthsOfYearEnum.MAI,
  monthsOfYearEnum.JUN
];

export const lastSemesterMonths = [
  monthsOfYearEnum.JUL,
  monthsOfYearEnum.AGO,
  monthsOfYearEnum.SET,
  monthsOfYearEnum.OUT,
  monthsOfYearEnum.NOV,
  monthsOfYearEnum.DEZ
];

export const allYear = [
  monthsOfYearEnum.JAN,
  monthsOfYearEnum.FEV,
  monthsOfYearEnum.MAR,
  monthsOfYearEnum.ABR,
  monthsOfYearEnum.MAI,
  monthsOfYearEnum.JUN,
  monthsOfYearEnum.JUL,
  monthsOfYearEnum.AGO,
  monthsOfYearEnum.SET,
  monthsOfYearEnum.OUT,
  monthsOfYearEnum.NOV,
  monthsOfYearEnum.DEZ
];

export const currentYear = new Date().getFullYear();
