/* eslint-disable @next/next/no-sync-scripts */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import RegisterContextProvider from '@/contexts/RegisterContextProvider';
import { hotjar } from 'react-hotjar';
import { SidebarProvider } from '@/contexts/SidebarContext';
import { TourProvider } from '@/contexts/TourContext';
import { Provider } from 'react-redux';
import { SWRConfig } from 'swr';
import { tourMock } from '@/mocks/tour';
import { ENABLE_EXTERNAL_SCRIPTS, ENABLE_NEW_RELIC, NEXT_PUBLIC_VERSION } from '@/constants/envs';
import '@/assets/tailwind.css';
import { REFRESH_INTERVAL_SWR_IN_MS, HOTJAR_ID, GTAG_ID, HOTJAR_SNIPPET_VERSION } from '@/constants/globalVars';
import { store } from '@/core/store';
import Script from 'next/script';
import { LogService } from '@/services/LogService';

LogService.init();

const MyApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    if (ENABLE_EXTERNAL_SCRIPTS) {
      hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);
    }
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="version" content={NEXT_PUBLIC_VERSION} />
        <title>Portal IES Parceiros</title>

        {ENABLE_EXTERNAL_SCRIPTS ? (
          <>
            <script type="text/javascript" src="/scripts/gtm.js" />
            <script type="text/javascript" src="/scripts/clarity.js" />
          </>
        ) : null}
      </Head>

      {ENABLE_EXTERNAL_SCRIPTS ? (
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GTAG_ID}`}
          height="0"
          title="Google TMG"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      ) : null}
      <Provider store={store}>
        <SidebarProvider>
          <RegisterContextProvider>
            <TourProvider data={tourMock}>
              <SWRConfig
                value={{
                  refreshInterval: REFRESH_INTERVAL_SWR_IN_MS,
                  fetcher: (resource, init) => fetch(resource, init).then((res) => res.json())
                }}
              >
                <Component {...pageProps} />
              </SWRConfig>
            </TourProvider>
          </RegisterContextProvider>
        </SidebarProvider>
      </Provider>
      {ENABLE_NEW_RELIC ? <Script type="text/javascript" src="/scripts/new_relic.js" /> : null}
    </>
  );
};

export default MyApp;
